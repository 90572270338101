/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-picker-input {
  font-size: 16px;
  /* height: 30px; */
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

svg {
  vertical-align: baseline;
}

.ant-form-item-label > label {
  font-weight: 600;
}

.subir-constancia .ant-upload.ant-upload-select {
  width: 100%;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}

.ant-row.ant-form-item {
  margin-bottom: 0;
}

.ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #1c6573;
  font-weight: bold;
}
